import React from 'react';

import $ from 'jquery';
import { cloneDeep, isEqual, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import AppContext from '../AppContext';
import Collapsible from './Collapsible';
import { WS_STATUS } from '../Constants';
import ForgetMe from './ForgetMe';
import { Switch } from '../elements';
import MySubscriptionsService from '../services/mysubscriptions-service';

class MySubscriptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldGroups: [],
      locale: {
        businessUnit: null,
        language: null,
      },
      wsException: false,
    };

    this.wsEndpoint = new MySubscriptionsService(null, null, null, '/api');

    /*
     * EVENT HANDLERS
     */

    this.onClickBadge = (event, badgeProps, badgeState) => {
      const $save = $('#btn-save');

      $save.attr('disabled', true);

      this.wsEndpoint.deleteCampaign(badgeProps.memberId, badgeState.checked, badgeProps.id)
        .then((response) => {
          if (response.success) {
            $save.attr('disabled', false);
          } else {
            $('#exceptionModal').modal();
          }
        });
    };

    this.onClickSwitch = (event, switchProps, switchState) => {
      const { value, setValue } = this.context;

      // Set wsStatus context value.
      setValue(
        { ...value.globalAlert },
        { ...value.locale },
        value.roadblocked,
        { ...value.settings },
        { ...value.strings },
        { ...value.theme },
        WS_STATUS.STATE.ACTIVE,
      );

      this.wsEndpoint.patchSubscription(switchProps.availableSubId, switchState.checked, switchProps.id)
        .then((response) => {
          const { fieldGroups } = this.state;

          if (response.success) {
            const newFieldGroups = cloneDeep(fieldGroups);

            newFieldGroups.forEach((fieldGroup) => {
              fieldGroup.subscriptions.forEach((subscription) => {
                const modifiedSubscription = subscription;

                if (modifiedSubscription.availableSubId === switchProps.availableSubId) {
                  modifiedSubscription.checked = switchState.checked;
                  modifiedSubscription.userSubId = response.data[0].sfid;
                }
              });
            });

            this.setState({ fieldGroups: newFieldGroups }, () => {
              // Set wsStatus context value.
              setValue(
                { ...value.globalAlert },
                { ...value.locale },
                value.roadblocked,
                { ...value.settings },
                { ...value.strings },
                { ...value.theme },
                WS_STATUS.STATE.COMPLETE,
              );
            });
          } else {
            $('#exceptionModal').modal();
          }
        });
    };

    this.onClickUnsubscribeAll = (event) => {
      event.preventDefault();

      const { value, setValue } = this.context;
      const $this = $(event.target);

      // Send GTM custom event.
      window.dataLayer.push({
        // data: label,
        event: 'Unsubscribe All Button Click',
        'gtm.element': event.target,
        'gtm.elementClasses': event.target.className || '',
        'gtm.elementId': event.target.id || '',
        'gtm.elementTarget': event.target.target || '',
        'gtm.elementUrl': event.target.href || event.target.action || '',
        'gtm.originalEvent': event,
      });

      // Set wsStatus context value.
      setValue(
        { ...value.globalAlert },
        { ...value.locale },
        value.roadblocked,
        { ...value.settings },
        { ...value.strings },
        { ...value.theme },
        WS_STATUS.STATE.ACTIVE,
      );

      $this.attr('disabled', true);

      this.wsEndpoint.deleteUnsubscribeAll()
        .then((response) => {
          const { fieldGroups } = this.state;

          if (response.success) {
            const newFieldGroups = cloneDeep(fieldGroups);

            newFieldGroups.forEach((fieldGroup) => {
              fieldGroup.subscriptions.forEach((subscription) => {
                const modifiedSubscription = subscription;

                modifiedSubscription.checked = false;
              });
            });

            this.setState({ fieldGroups: newFieldGroups }, () => {
              // Set wsStatus context value.
              setValue(
                { ...value.globalAlert },
                { ...value.locale },
                value.roadblocked,
                { ...value.settings },
                { ...value.strings },
                { ...value.theme },
                WS_STATUS.STATE.COMPLETE,
              );

              $this.attr('disabled', false);
            });
          } else {
            $('#exceptionModal').modal();
          }
        })
        .catch(() => {
          $('#exceptionModal').modal();

          // Set wsStatus context value.
          setValue(
            { ...value.globalAlert },
            { ...value.locale },
            value.roadblocked,
            { ...value.settings },
            { ...value.strings },
            { ...value.theme },
            WS_STATUS.STATE.COMPLETE,
          );

          $this.attr('disabled', false);
        });
    };

    /*
    * HELPER METHODS
    */

    this.collapsibleIsActive = (subscriptions) => {
      const isInactive = (subscription) => subscription.checked !== true;

      // Additional categorys are closed if they do not contain subscriptions.
      if (subscriptions.length === 0) return false;

      // Additional categories are closed if they contain subscriptions but all are not checked.
      if (subscriptions.every(isInactive)) return false;

      return true;
    };

    this.fetchData = () => {
      this.wsEndpoint.get()
        .then((response) => {
          const { data, success } = response;

          if (!success) throw new Error();

          const sortedfieldGroups = sortBy(data, 'catorder');

          sortedfieldGroups.forEach((fieldGroup) => {
            const modifiedFieldGroup = fieldGroup;
            const filteredSubscriptions = modifiedFieldGroup.subscriptions.filter((subscription) => subscription.public === true || subscription.userSubId !== null);
            const sortedSubscriptions = sortBy(filteredSubscriptions, 'order');

            sortedSubscriptions.forEach((subscription) => {
              const modifiedSubscription = subscription;
              const sortedCampaigns = sortBy(modifiedSubscription.campaigns, 'order');

              modifiedSubscription.campaigns = sortedCampaigns;
            });

            modifiedFieldGroup.subscriptions = sortedSubscriptions;
          });

          this.setState({ fieldGroups: sortedfieldGroups });
        })
        .catch(() => {
          this.setState({ wsException: true, fieldGroups: [] });
        });
    };
  }

  /*
   * LIFECYCLE METHODS
   */

  componentDidMount() {
    const { value } = this.context;

    this.setState({ locale: { ...value.locale } });
  }

  componentDidUpdate(prevProps, prevState) {
    const { setValue, value } = this.context;
    const { fieldGroups, locale } = this.state;

    if (this.context && value && !isEqual(value.locale, locale)) {
      this.setState({ locale: { ...value.locale } });
    }

    if (!isEqual(prevState.locale, locale)) {
      this.wsEndpoint.id = value.id;
      this.wsEndpoint.bu = value.locale.businessUnit;
      this.wsEndpoint.lang = value.locale.language;

      this.fetchData();
    }

    if (!isEqual(prevState.fieldGroups, fieldGroups)) {
      // If "availableSubId" exists in the URL query string then the user should be automatically opted-in to the matching subscription.
      if (value.availableSubId) {
        const clonedFieldGroups = cloneDeep(fieldGroups);

        // Test to see if the specified ID exists somewhere in the collection.
        const subscription = (clonedFieldGroups.flatMap((fieldGroup) => fieldGroup.subscriptions.filter((s) => s.availableSubId === value.availableSubId && s.checked !== true))[0] || null);

        if (subscription) {
          // If the indicated ID exists then call the web service to subscribe the user and update the UI.
          this.wsEndpoint.patchSubscription(subscription.availableSubId, true)
            .then((response) => {
              if (response.success === 'fail') {
                $('#exceptionModal').modal();
              } else {
                subscription.checked = true;

                this.setState({ fieldGroups: clonedFieldGroups });
              }
            });

          // Show a global alert
          setValue(
            {
              dismissed: false,
              message: `${value.strings.globalAlert_autoSubscribe} &ldquo;${subscription.label}&rdquo;`,
              type: 'success',
            },
            { ...value.locale },
            value.roadblocked,
            { ...value.settings },
            { ...value.strings },
            { ...value.theme },
            value.wsStatus,
          );
        }
      }
    }
  }

  render() {
    const { value } = this.context;
    const { id } = this.props;
    const { fieldGroups, wsException } = this.state;

    const mappedFieldGroups = fieldGroups.map((fieldGroup, index) => {
      const openOnLaunch = (index === 0 ? true : this.collapsibleIsActive(fieldGroup.subscriptions));

      let category = null;

      if (fieldGroup.subscriptions.length) {
        const collapsibleTiles = fieldGroup.subscriptions.map((subscription) => (
          <div className="collapsible-tile" key={subscription.availableSubId}>
            <Switch availableSubId={subscription.availableSubId} callback={this.onClickSwitch} callbackBadge={this.onClickBadge} campaigns={subscription.campaigns} channel={subscription.channel} checked={subscription.checked} description={subscription.description} disabled={subscription.disabled} id={subscription.id} label={subscription.label} userSubId={subscription.userSubId} />
          </div>
        ));

        category = <Collapsible id={fieldGroup.catid} key={fieldGroup.catid} label={fieldGroup.catlabel} openOnLaunch={openOnLaunch} tiles={collapsibleTiles} />;
      }

      return category;
    });

    return (
      <>
        <div className={`alert alert-danger${wsException ? '' : ' d-none'}`} role="alert">
          <svg className="bi bi-alert-circle-fill" width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8.998 3a1 1 0 112 0 1 1 0 01-2 0zM10 6a.905.905 0 00-.9.995l.35 3.507a.553.553 0 001.1 0l.35-3.507A.905.905 0 0010 6z" clipRule="evenodd" />
          </svg>
          {value.strings.wsGetError}
        </div>
        {mappedFieldGroups}
        <div className="row">
          <div className="col-12">
            <Route exact path={`/${id}`}>
              <button className="btn btn-lg btn-primary" id="btn-save" onClick={this.onClickSaveButton} type="button">
                {value.strings.button_submit}
              </button>
            </Route>
            <button className="btn btn-large btn-secondary float-right" disabled={wsException} onClick={this.onClickUnsubscribeAll} type="button">{value.strings.button_unsubscribeAll}</button>
            {(value.settings.forgetMeEnabled ? <ForgetMe className="float-right mr-2" /> : null)}
          </div>
        </div>
      </>
    );
  }
}

MySubscriptions.contextType = AppContext;

MySubscriptions.propTypes = {
  id: PropTypes.string.isRequired,
};

export default MySubscriptions;
