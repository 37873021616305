const DEFAULT = null;

const ISVALID = {
  NO: 'is-invalid',
  UNKNOWN: '',
  YES: 'is-valid',
};

const SOURCE = 'Preference Center';

const WS_STATUS = {
  DURATION: 1000,
  STATE: {
    ACTIVE: 'active',
    COMPLETE: 'complete',
    IDLE: 'idle',
  },
};

export { DEFAULT as default };
export { ISVALID };
export { SOURCE };
export { WS_STATUS };
